<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/cm.svg">
    <HelloWorld msg="Links"/>

    <LinksTopic nome="Links" texto_link="foi" link="https://github.com/vuejs/vue-cli/tree/dev/packages/%40vue/cli-plugin-babel"/>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import LinksTopic from '@/components/LinksTopic.vue'

export default {
  name: 'HomeView',
  components: {
    HelloWorld, LinksTopic
  }
}
</script>

<style scoped>
img{
  height: 25vw;
}
</style>