<template>
    <div class="links">
      <h3>{{ nome }}</h3>
      <ul>
        <li><a href="{{ link }}" target="_blank" rel="noopener">{{texto_link}}</a></li>
        <li><a href="https://github.com/vuejs/vue-cli/tree/dev/packages/%40vue/cli-plugin-router" target="_blank" rel="noopener">router</a></li>
        <li><a href="https://github.com/vuejs/vue-cli/tree/dev/packages/%40vue/cli-plugin-eslint" target="_blank" rel="noopener">eslint</a></li>
        <li><a href="https://github.com/vuejs/vue-cli/tree/dev/packages/%40vue/cli-plugin-e2e-cypress" target="_blank" rel="noopener">e2e-cypress</a></li>
      </ul>
    </div>
</template>

<script>
export default {
    name: 'LinksTopic',
    props: {
        nome: String,
        texto_link: String,
        link: String
    }
}
</script>

<style scoped>
.links{
  background-color: #ECEEE9;
  margin: 0 20%;
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>